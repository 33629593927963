import clsx from 'clsx';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';

import { CloseButton } from '@molecules';
import { IconButton } from '@atoms';
import { ArrowDropDownLine } from '@components/icons/System';
import { FocusLock } from '@components';
import { CenteredSpinner } from '@organisms';
import useInOutAnimation from '@hooks/useInOutAnimation';
import { useMediaQuery, useTranslate } from '@hooks';
import { common } from '@microcopies';
import { breakpoints } from '@src/utils';

const Drawer = ({
  isOpen,
  onClose,
  onCloseDone,
  children,
  bottomBar,
  closeButton,
  className,
  isLoading = false,
  hideDefaultButton = false,
  alwaysOpenFromBottom = false
}: {
  isOpen: boolean;
  onClose(): void;
  onCloseDone?(): void;
  children: React.ReactNode;
  bottomBar?: React.ReactNode;
  closeButton?: React.ReactNode;
  hideDefaultButton?: boolean;
  className?: string;
  isLoading?: boolean;
  alwaysOpenFromBottom?: boolean;
}) => {
  const { isVisible, isOpenOrOpening, onTransitionEnd } = useInOutAnimation(
    isOpen,
    {
      onVisibilityChange: onCloseDone
        ? (v) => {
            if (!v) {
              onCloseDone();
            }
          }
        : undefined
    }
  );
  const [maxWidth, setMaxWidth] = useState<string | undefined>(undefined);
  const bgElement = useRef<HTMLDivElement>(null);
  const containerElement = useRef<HTMLDivElement>(null);
  const contentElement = useRef<HTMLDivElement>(null);
  const innerContentElement = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery(breakpoints.tablet, true);
  const isLaptop = useMediaQuery(breakpoints.laptop, true);
  const translateCommon = useTranslate(common, (x) => x.common);

  useEffect(() => {
    // Maintain initial width of Drawer on screens wider than 768px
    if (!maxWidth && containerElement.current && window.innerWidth > 768) {
      setMaxWidth(`${containerElement.current.clientWidth}px`);
    } else if (window.innerWidth <= 768) {
      setMaxWidth(`${window.innerWidth}px`);
    }
  }, [containerElement.current]);

  const [clickStartedOnContainer, setClickStartedOnContainer] = useState(false);
  const onBackdropClick: MouseEventHandler<HTMLDivElement> = (e) => {
    /**
     * Prevent closing if click was not made directly to the backdrop,
     * or when user drags into the bg element during click.
     */
    if (e.target !== bgElement.current || clickStartedOnContainer) {
      return;
    }
    onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <FocusLock isLocked>
      <div
        role="dialog"
        aria-labelledby="drawerTitle"
        aria-describedby="drawerDescription"
        ref={bgElement}
        className={clsx(
          'fixed z-[210] inset-0 flex flex-col justify-end items-center bg-black transition-colors duration-300 tablet:items-end',
          {
            'bg-opacity-0': !isOpenOrOpening,
            'bg-opacity-50': isOpenOrOpening
          }
        )}
        onClick={onBackdropClick}
        onMouseDown={() => setClickStartedOnContainer(false)}
      >
        <div
          ref={containerElement}
          className={clsx(
            'transition-transform duration-300 flex flex-col roll-up-max-height relative min-w-[100vw] max-w-[100vw]',
            {
              'translate-y-full': !isOpenOrOpening,
              'tablet:translate-x-full tablet:translate-y-0':
                !isOpenOrOpening && !alwaysOpenFromBottom,
              'tablet:max-w-[50vw] tablet:min-w-[700px] tablet:min-h-screen':
                !alwaysOpenFromBottom
            }
          )}
          style={{ maxWidth }}
          onTransitionEnd={onTransitionEnd}
          onMouseDown={(e) => {
            e.stopPropagation();
            setClickStartedOnContainer(true);
          }}
        >
          <div
            ref={contentElement}
            className={clsx('flex-auto h-full overflow-y-auto bg-white', {
              [className || '']: className
            })}
          >
            {closeButton ||
              (hideDefaultButton ? null : (
                <>
                  {isLaptop && !isMobile && (
                    <CloseButton
                      data-testid="drawer-close-button"
                      className="mr-5 sticky right-6 top-6 ml-auto -mb-10 z-[230]"
                      onClick={onClose}
                    />
                  )}
                  {isMobile && (
                    <IconButton
                      size="large"
                      aria-label={translateCommon((x) => x.close)}
                      onClick={onClose}
                      data-testid="drawer-close-button"
                      className="mr-5 sticky right-6 top-6 ml-auto -mb-10 z-[230]"
                      icon={ArrowDropDownLine}
                    />
                  )}
                </>
              ))}

            <div ref={innerContentElement}>{children}</div>
          </div>
          {bottomBar && (
            <div
              className={clsx(
                'absolute bottom-0 right-0 left-0 w-full border-t bg-off-black text-white'
              )}
            >
              {bottomBar}
            </div>
          )}
          {isLoading && <CenteredSpinner absolute />}
        </div>
      </div>
    </FocusLock>
  );
};

export default Drawer;
