import React from 'react';
import clsx from 'clsx';

import { CloseLine } from '@icons/System';
import { IconButton } from '@atoms';
import { ButtonModes } from '@constants';

const CloseButton = ({
  icon = CloseLine,
  iconColor = 'text-black',
  buttonTailwindColor = 'bg-gray-300',
  onClick,
  className = '',
  ...rest
}: {
  icon?: TIconType;
  iconColor?: string;
  buttonTailwindColor?: string;
  onClick: () => void;
  className?: string;
}) => (
  <IconButton
    className={clsx(
      'sticky w-10 h-10 right-6 top-6 px-0 py-0 ml-auto -mb-10 z-[230]',
      {
        [buttonTailwindColor]: buttonTailwindColor,
        [iconColor]: iconColor,
        [className]: className
      }
    )}
    buttonColor={buttonTailwindColor === 'bg-white' ? 'white' : undefined}
    size="large"
    iconClassName="w-3 h-3"
    aria-label="close"
    mode={ButtonModes.none}
    icon={icon}
    fill={iconColor}
    onClick={onClick}
    {...rest}
  />
);

export default CloseButton;
