/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const downtimeBanner: Record<string, TDowntimeBanner> = {
  'en-AU': {
    downtimeBanner: {
      label: 'Sorry we can\'t display all expeditions right now. Please feel free to  call us on {phone} to find your perfect cruise'
    }
  },
  'en-GB': {
    downtimeBanner: {
      label: 'Sorry we can\'t display all expeditions right now. Please feel free to  call us on {phone} to find your perfect cruise'
    }
  },
  'en-US': {
    downtimeBanner: {
      label: 'Sorry we can\'t display all expeditions right now. Please feel free to  call us on {phone} to find your perfect cruise'
    }
  },
  'de-DE': {
    downtimeBanner: {
      label: 'Leider können wir im Moment nicht alle Expeditionen anzeigen. Bitte rufen Sie uns an unter {phone}, um Ihre perfekte Expedition zu finden.'
    }
  },
  'gsw-CH': {
    downtimeBanner: {
      label: 'Leider können wir im Moment nicht alle Expeditionen anzeigen. Bitte rufen Sie uns an unter {phone}, um Ihre perfekte Expedition zu finden.'
    }
  },
  'fr-FR': {
    downtimeBanner: {
      label: 'Nous sommes désolés de ne pas pouvoir afficher toutes les expéditions pour le moment. N\'hésitez pas à nous appeler au {phone} pour trouver l\'expédition qui vous convient.'
    }
  },
  'da-DK': {
    downtimeBanner: {
      label: 'Vi kan desværre ikke vise alle ekspeditioner lige nu. Du er velkommen til at ringe til os på {phone} for at finde din perfekte'
    }
  },
  'sv-SE': {
    downtimeBanner: {
      label: 'Tyvärr kan vi inte visa alla expeditioner just nu. Du är välkommen att ringa oss på {phone} för att hitta din perfekta'
    }
  },
  'nb-NO': {
    downtimeBanner: {
      label: 'Beklager at vi ikke kan vise alle ekspedisjonene akkurat nå. Ring oss gjerne på {phone} for å finne din perfekte ekspedisjon'
    }
  },
  'en': {
    downtimeBanner: {
      label: 'Sorry we can\'t display all expeditions right now. Please feel free to  call us on {phone} to find your perfect cruise'
    }
  }
}

export type TDowntimeBanner = {
  downtimeBanner: {
    label: string;
  };
}
