import React, { useState } from 'react';
import clsx from 'clsx';
import { Button } from '@hurtigruten/design-system-components';

import { PageSection } from '@atoms';
import { Fluid } from '@components';
import { useTranslate, useLocale } from '@hooks';
import { landing } from '@src/microcopies/sets/landing';
import { destination as destinationCopy } from '@microcopies';
import { ImageCard, ImageCardLink } from '@components/molecules';
import { DestinationLinkCard } from '@content/models';
import { mapLocaleToDestinationsUri } from '@src/utils/mappers/uriMappers';
import { DestinationNew } from '@content/gql/graphql';

const MustSeeDestinations = ({
  allDestinationCards,
  destinations,
  hasTitle = true,
  backgroundColor = 'white',
  showExploreAll = true,
  isNoMargin = false
}: {
  allDestinationCards?: DestinationLinkCard[] | null;
  backgroundColor?: 'white' | 'hx-caviar' | 'gray';
  hasTitle?: boolean;
  destinations: DestinationNew[];
  showExploreAll?: boolean;
  isNoMargin?: boolean;
}) => {
  const translateLanding = useTranslate(landing, (x) => x.landing);
  const [isExpanded, toggleIsExpanded] = useState(false);

  const remainingDestinations = allDestinationCards?.filter(
    (dest) => !destinations.find((d) => d.slug === dest.slug)
  );

  const translateDestination = useTranslate(
    destinationCopy,
    (x) => x.destination
  );

  const locale = useLocale();

  return (
    <PageSection
      backgroundColor={backgroundColor}
      title={
        hasTitle ? translateLanding((x) => x.destinationsTitle) : undefined
      }
    >
      <div
        className={clsx('flex flex-col w-full', {
          'text-white': backgroundColor === 'hx-caviar',
          'text-black': backgroundColor !== 'hx-caviar'
        })}
      >
        <Fluid isNoMargin={isNoMargin} className="flex flex-col w-full">
          <div
            className={clsx(
              'grid w-full grid-cols-2 gap-3 grid-rows justify-items-stretch tablet:grid-rows-1 tablet:grid-cols-4 tablet:gap-6'
            )}
          >
            {destinations.map((destination, i) => (
              <ImageCard
                key={`destination_${i}`}
                slug={`/${mapLocaleToDestinationsUri(locale)}/${
                  destination.slug
                }`}
                imageUrl={destination?.introHero?.image?.url}
                alt={destination.name}
                title={destination.name}
                subTitle={translateDestination((x) => x.destinationsButton)}
              />
            ))}
          </div>
          {showExploreAll && remainingDestinations && (
            <div
              id="remaining-destinations"
              data-testid="remaining-destinations-section"
              className={clsx('w-full mt-12 flex flex-col gap-12', {
                hidden: !isExpanded
              })}
            >
              <h4>{translateDestination((x) => x.title)}</h4>
              <div className="grid grid-cols-1 tablet:grid-cols-3 gap-x-6 gap-y-8">
                {remainingDestinations.map((destination) => (
                  <ImageCardLink
                    key={destination.sysId}
                    href={`${mapLocaleToDestinationsUri(locale)}/${
                      destination.slug
                    }/`}
                    imageAlt={
                      destination.heroBanner.heroImage.image.description ??
                      destination.name
                    }
                    imageSrc={destination.heroBanner.heroImage.image.url}
                    title={destination.name}
                  />
                ))}
              </div>
            </div>
          )}
          {showExploreAll && (
            <div className="mt-6 tablet:mt-12">
              <Button
                icon={isExpanded ? 'chevron-up' : 'chevron-down'}
                text={translateLanding((x) => x.destinationsButton)}
                onClick={() => toggleIsExpanded(!isExpanded)}
                aria-controls="remaining-destinations"
                aria-expanded={isExpanded}
                testId="toggle-all-destinations"
                appearance={
                  backgroundColor === 'hx-caviar' ? 'secondary' : 'primary'
                }
              />
            </div>
          )}
        </Fluid>
      </div>
    </PageSection>
  );
};

export default MustSeeDestinations;
