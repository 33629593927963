import { useEffect, useRef, useState } from 'react';

export enum AnimationState {
  Closed,
  BeforeOpening,
  Opening,
  Open,
  Closing
}

const useInOutAnimation = (
  isOpen: boolean,
  { onVisibilityChange }: { onVisibilityChange?(visible: boolean): void }
) => {
  const isFirstRun = useRef(true);
  const [animationState, setAnimationState] = useState(AnimationState.Closed);

  const isVisible = animationState !== AnimationState.Closed;

  useEffect(() => {
    if (isOpen) {
      // BeforeOpening state is used to render the state
      // which the Drawer will transition in from.
      setAnimationState(AnimationState.BeforeOpening);
    } else if (animationState !== AnimationState.Closed) {
      setAnimationState(AnimationState.Closing);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isFirstRun.current && onVisibilityChange) {
      onVisibilityChange(isVisible);
    }
  }, [isVisible]);

  useEffect(() => {
    if (animationState === AnimationState.BeforeOpening) {
      // The BeforeOpening state must be active at least two
      // frames to trigger the transition.
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          setAnimationState(AnimationState.Opening);
        });
      });
    }
  }, [animationState]);

  const onTransitionEnd = () => {
    if (animationState === AnimationState.Opening) {
      setAnimationState(AnimationState.Open);
    } else if (animationState === AnimationState.Closing) {
      setAnimationState(AnimationState.Closed);
    }
  };

  useEffect(() => {
    isFirstRun.current = false;
  }, []);

  const isOpenOrOpening =
    animationState === AnimationState.Open ||
    animationState === AnimationState.Opening;

  return {
    animationState,
    isOpenOrOpening,
    isVisible,
    onTransitionEnd
  };
};

export default useInOutAnimation;
