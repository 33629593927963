/** @file Exports the main DowntimeBanner component. */

import { Banner, Icon } from '@hurtigruten/design-system-components';

import { useTranslate } from '@hooks';
import { downtimeBanner, appbar } from '@microcopies';
import { getPhoneNumber } from '@src/utils/locale/phoneNumber';
import { escapeRegExp } from '@src/utils/escapeRegExp';

/** #### Downtime banner which is displayed when voyage availability drops significantly below the normal level.
 * - <https://hurtigruten.atlassian.net/browse/NEL-2164 has the Figma link>
 * - Toggled using the `availability_downtime_banner` feature flag in optimizely
 * - The banner is displayed on pages which show cruise cards (landing, offers, offer, cruises, destination)
 */

const DowntimeBanner = ({
  containerClassName
}: {
  containerClassName?: string;
}) => {
  const translate = useTranslate(downtimeBanner, (x) => x.downtimeBanner);
  const translateAppbar = useTranslate(appbar, (x) => x.appbar);

  const phoneNumber = getPhoneNumber(translateAppbar);
  const downtimeBannerText = translate((x) => x.label, { phone: phoneNumber });

  const splitTextOnPhoneNumber = downtimeBannerText.split(
    new RegExp(`(${escapeRegExp(phoneNumber)})`, 'gi')
  );

  return (
    <div className={containerClassName}>
      <Banner appearance="error">
        <div className="flex">
          <div className="flex items-start mr-4">
            <Icon graphic="info" />
          </div>
          <div role="alert" aria-live="assertive">
            {splitTextOnPhoneNumber.map((text) =>
              text === phoneNumber ? (
                <a className="underline" href={`tel: ${phoneNumber}`}>
                  {phoneNumber}
                </a>
              ) : (
                text
              )
            )}
          </div>
        </div>
      </Banner>
    </div>
  );
};

export default DowntimeBanner;
